'use strict';

import {addCustomEventListener, addClass, removeClass} from '../helpers';

export const homeSlider = (() => {
  const _parent = document.querySelector('[data-home-slider="parent"]');
  let _slides = null;
  let slideNum = 0;
  let sliderCnt = 0;

  const sliderInit = () => {
    sliderCnt = _slides.length;
    showSlide();
    setTimeout(() => {
      addClass(_parent, 'showed');
    }, 0);
  };

  const showSlide = () => {
    removeClass(_slides, 'active');
    removeClass(_parent.querySelectorAll(`[data-home-pagin]`), 'active');
    removeClass(_parent.querySelectorAll(`[data-home-bg]`), 'active');
    removeClass(_parent.querySelectorAll(`[data-home-next]`), 'active');
    _slides.forEach(_slide => {
      const _num = parseInt(_slide.getAttribute('data-home-slide'));
      if (_num === slideNum) {
        addClass(_slide, 'active');
        addClass(_parent.querySelector(`[data-home-pagin="${_num}"]`), 'active');
        addClass(_parent.querySelector(`[data-home-bg="${_num}"]`), 'active');
        addClass(_parent.querySelector(`[data-home-next="${_num}"]`), 'active');
      }
    })
  };

  const setSlideNum = (num) => {
    let newNum = num;
    if (num < 0) {
      newNum = sliderCnt - 1;
    }
    if (num >= sliderCnt) {
      newNum = 0;
    }
    if (slideNum !== newNum) {
      slideNum = newNum;
      showSlide();
    }
  };

  const eventListeners = () => {
    addCustomEventListener('[data-home-pagin]', 'click', function (e) {
      e.preventDefault();
      let _el = e.target;
      if (!_el.hasAttribute('data-home-pagin')) {
        _el = _el.closest('[data-home-pagin]');
      }
      const pagin = parseInt(_el.getAttribute('data-home-pagin') || null);
      setSlideNum(pagin);

      return false;
    });
    addCustomEventListener('[data-home-slider="next"]', 'click', function (e) {
      e.preventDefault();
      setSlideNum(slideNum + 1);

      return false;
    });
  };

  const init = () => {
    if (!_parent) return false;

    _slides = _parent.querySelectorAll('[data-home-slide]');
    if (!_slides.length) return false;

    sliderInit();
    eventListeners();
  };

  return {
    init
  }
})();


