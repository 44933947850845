'use strict';

import {addCustomEventListener, addClass, removeClass} from '../helpers';

export const popupImage = (() => {
  const _popup = document.querySelector('[data-popup="show-image"]');

  const showImage = (src) => {
    if (!src) return;

    const _popupImg = _popup.querySelector('[data-popup-image="src"]');
    _popupImg.removeAttribute('src');
    const img = new Image();
    img.onload = function () {
      _popupImg.src = src;
      addClass(_popup, 'opened');
    };
    img.src = src;
  };

  const eventListeners = () => {
    addCustomEventListener('[data-open-image]', 'click', function (e) {
      e.preventDefault();
      let _el = e.target;
      if (!_el.hasAttribute('data-open-image')) {
        _el = _el.closest('[data-open-image]');
      }
      const src = _el.getAttribute('data-open-image') || null;
      showImage(src);

      return false;
    });
    addCustomEventListener('[data-popup-image="close"]', 'click', function (e) {
      removeClass(_popup, 'opened');
    });
  };

  const init = () => {
    if (!document.querySelectorAll('[data-open-image]').length || !_popup) return false;

    eventListeners();
    window.openPopupImage = function (src) {
      showImage(src);
    }
  };

  return {
    init
  }
})();


