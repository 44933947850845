'use strict';

import Swiper from "swiper";
// import {addCustomEventListener, addClass, removeClass} from '../helpers';

export const homeProjects = (() => {
  const _block = document.querySelector('[data-home="projects"]');
  let prSwiper = null;
  let cntSlides = 0;

  const swiperInit = () => {
    let _gallery = _block.querySelector('.swiper-container');
    const _prev = _block.querySelector('.home_projects__nav__btn.prev');
    const _next = _block.querySelector('.home_projects__nav__btn.next');

    prSwiper = new Swiper(_gallery, {
      speed: 400,
      slidesPerView: 2,
      navigation: {
        nextEl: _next,
        prevEl: _prev,
      },
      // pagination: {
      //   el: _pag,
      //   clickable: true,
      // },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        641: {
          slidesPerView: 2,
          spaceBetween: 0
        },
      },
      on: {
        init: updateNums,
        slideChange: updateNums,
        breakpoint: updateNums
      }
    });
  };

  const updateNums = () => {
    setTimeout(() => {
      const num = prSwiper.realIndex + 1;
      const numSlides = window.innerWidth <= 640 ? num : `${num}-${num + 1}`;
      _block.querySelector('.home_projects__nav__nums').innerHTML = `<span>${numSlides}</span> / <span>${cntSlides}</span>`;
    }, 50);
  };

  const init = () => {
    if (!_block) return false;

    cntSlides = _block.querySelectorAll('.project-item').length;
    swiperInit();
  };

  return {
    init
  }
})();


