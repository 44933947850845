'use strict';
// import {TweenLite, Power1, Circ} from "gsap/TweenMax";
import Swiper from 'swiper';
import {parseZone} from "moment";

let showedSection = 'home';
let canvasIdsArray = [];
export const globalFunc = () => {
  titles();
  // mouseMove();
  scrollAnim();
  //
  // form();
  menu();
  loader();

  homeCost();
  pandaModal.init();

  $(document).on('click', '[data-type="menu-toggle"]', function () {
    $('.head-main').toggleClass('head-main--opened');
  });
  $(document).on('click', '.head-main a', function () {
    $('.head-main').removeClass('head-main--opened');
  });
};

const pandaModal = (() => {
  const $mdl = $('.panda_mdl');
  const start = () => {
    $(document).on('click', '[data-type="modal-open"]', function () {
      open();
    });

    $mdl.on('click', '.panda_mdl__bg, .panda_mdl__close', function () {
      $mdl.removeClass('opened');
    });
  };
  const open = (br_id = 0, gr_id = 0) => {
    $mdl.addClass('opened');
    $(`.request__form [name="school"] option[value="${br_id}"]`).prop('selected', true);
    $(`.request__form [data-branch-id]`).hide();
    $(`.request__form [data-branch-id="${br_id}"]`).show();
    $(`.request__form [data-branch-id="${br_id}"] [name="group"] option[value="${gr_id}"]`).prop('selected', true);
    $mdl.find('input[name="name"]').focus();
  };


  return {
    init: () => {
      start();
    },
    show: (br_id, gr_id) => {
      open(br_id, gr_id);
    }
  }
})();

const homeCost = () => {
  $(document).on('click', '[data-type="select-group"]', function () {
    const $el = $(this);
    const br_id = parseInt($el.closest('[data-group-branch]').data('group-branch') || 0);
    const gr_id = parseInt($el.closest('[data-group-id]').data('group-id') || 0);
    pandaModal.show(br_id, gr_id);
  });
  $(document).on('click', '[data-branch]:not(.active)', function () {
    const $el = $(this);
    const br_id = parseInt($el.data('branch') || 0);

    $('[data-branch]').removeClass('active');
    $('[data-group-branch]').removeClass('active');
    $(`[data-branch="${br_id}"]`).addClass('active');
    $(`[data-group-branch="${br_id}"]`).addClass('active');
  });
};

const homeSwiperInit = () => {
  $('.photos ').each(function (i, el) {
    const $pht = $(el);
    const _el = $pht.find('.photos__swiper')[0];
    const _prev = $pht.find('.photos__btn.prev')[0];
    const _next = $pht.find('.photos__btn.next')[0];
    const _pag = $pht.find('.swiper-pagination')[0];
    new Swiper(_el, {
      speed: 400,
      spaceBetween: 30,
      slidesPerView: 3,
      navigation: {
        nextEl: _next,
        prevEl: _prev,
      },
      pagination: {
        el: _pag,
        clickable: true,
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        431: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        781: {
          slidesPerView: 3,
          spaceBetween: 25
        },
      }
    });
  })
};

const loader = () => {
  $(window).on('load', () => {
    let tS = 1500 - (Date.now() - window.__openTime);
    if (tS < 50) {
      tS = 50;
    }
    setTimeout(() => {
      $('body').addClass('loaded');
      scrollAnimEls($(window).scrollTop(), $(window).height());
      scrollHeader($(window).scrollTop(), $(window).height());
      homeSwiperInit();
      const hash = window.location.hash;
      hashChange(hash);
      BGsHeight();
      scrollBottomBtn();
    }, tS);
  });
  $(window).on('resize', function(e) {
    BGsHeight();
  });
  $(window).on('hashchange', function(e) {
    e.preventDefault();
    hashChange(window.location.hash, 500);
    return false;
  });
};

const scrollBottomBtn = () => {
  $(document).on('click', '[data-type="scroll_bottom"]', function () {
    const $section = $(`[data-section="services"]`);
    const sectionT = $section.offset().top - 80;
    $('html, body').animate({scrollTop: sectionT}, 0);
    return false;
  });
};

const BGsHeight = () => {
  $('.projects-section__bg').css({
    height: $('.projects-section').outerHeight() + $('.clients-section').outerHeight()
  });
  $('.get-in-touch-section__bg').css({
    height: $('.get-in-touch-section').outerHeight() + $('.contacts-section').outerHeight()
  });
};

const menu = () => {
  $(document).on('click','[data-menu="toggle"]', function () {
    $('#app').toggleClass('menu-open');
  })
};

const scrollAnimEls = (top, heigth) => {
  $('[data-anim]').each((i, el) => {
    const elTop = $(el).offset().top;
    const elH = $(el).innerHeight();
    if ((top + heigth) > elTop && (elTop + elH) > (top)) {
      $(el).attr('data-anim', 'true');
    } else {
      $(el).attr('data-anim', 'false');
    }
  })
};

let needScroll = false;
const scrollAnim = () => {
  $(window).on('scroll', () => {
    const scrTop = $(window).scrollTop();
    const scrH = $(window).height();

    scrollAnimEls(scrTop, scrH);
    scrollHeader(scrTop, scrH);

    $('[data-section]').each(function(i, el) {
      const $el = $(el);
      const section = $el.data('section');
      const sectionT = $el.offset().top;
      const sectionH = $el.outerHeight();
      if (sectionT < scrTop && sectionT + sectionH > scrTop) {
        // needScroll = false;
        if (window.location.hash !== section) {
          // window.location.hash = showedSection;
          // console.log('set showedSection to:', section, sectionH);
        }
        showedSection = section;
      }
    });
  });
};

const scrollHeader = (scrTop, scrH) => {
  if(scrTop > 0) {
    if(!$('#app').hasClass('not-in-top')) {
      $('#app').addClass('not-in-top');
    }
  } else {
    if($('#app').hasClass('not-in-top')) {
      $('#app').removeClass('not-in-top');
    }
  }

  if (scrTop <= scrH) {
    const homeFigTop = scrTop / 2.2;
    // TweenLite.to($('.home-section .figure')[0], 0, {
    //   transform: `translate3d(0, ${homeFigTop}px, 0)`
    // });
    // $('.home-section .figure').css({
    //   transform: `translate3d(0, ${homeFigTop}px, 0)`
    // });
  }

  // if (scrTop <= $('[data-section="home"]').outerHeight()+$('[data-section="services"]').outerHeight() ) {
  //   const servFig = (scrTop - $('[data-section="home"]').outerHeight()) / -3;
  //   $('[data-section="services"] .top-figure').css({
  //     transform: `translate3d(0, ${servFig}px, 0)`
  //   });
  //   // TweenLite.to($('[data-section="services"] .figure')[0], 0, {
  //   //   transform: `translate3d(0, ${servFigTop}px, 0)`
  //   // });
  // }

  const float_blocks = document.querySelectorAll('.float_block-socials');
  if (float_blocks[0] && float_blocks[1]) {
    if (float_blocks[1].getBoundingClientRect().y < float_blocks[0].getBoundingClientRect().y) {
      $('header .float_block').css('opacity', 1);
      $('.home-section .float_block').css('opacity', 0);
    } else {
      $('header .float_block').css('opacity', 0);
      $('.home-section .float_block').css('opacity', 1);
    }
  }
};

const hashChange = (hash, speed = 0) => {
  hash = hash.replace('#','');
  const section = hash === "" ? 'home' : hash;
  showedSection = section;
  const $section = $(`[data-section="${section}"]`);
  if($section.length) {
    const sectionT = $section.offset().top - 40;
    $('html, body').animate({scrollTop: sectionT}, speed);
  }
  $('#app').removeClass('menu-open');
};

const sliderInit = () => {
  const mySwipers = [];
  if($('[data-tab] .projects-section__wrap .swiper-container').length) {
    $(document).on('click','[data-tab-click]', function () {
      const tab = $(this).data('tab-click');
      console.log({tab});
      $(`[data-tab-click]`).removeClass('active');
      $(`[data-tab-click="${tab}"]`).addClass('active');
      $(`[data-tab]`).slideUp(250, function () {
        scrollAnimEls($(window).scrollTop(), $(window).height());
      });
      $(`[data-tab="${tab}"]`).slideDown(500, function () {
        scrollAnimEls($(window).scrollTop(), $(window).height());
      });
    });
    $('[data-tab-click]:first').click();
    $('[data-tab]').each((i,el) => {
      const tab = $(el).data('tab');
      mySwipers[i] = new Swiper(`[data-tab="${tab}"] .projects-section__wrap .swiper-container`, {
        // speed: 400,
        init: false,
        navigation: {
          nextEl: `[data-tab="${tab}"] .swiper-button-next`,
          prevEl: `[data-tab="${tab}"] .swiper-button-prev`,
        },
        pagination: {
          el: `[data-tab="${tab}"] .projects-section__bottom-pagin`,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="pagin-dot ' + className + '"></span>';
          },
        },
        grabCursor: true,
        slidesPerView: 3,
        spaceBetween: 50,
        breakpoints: {
          430: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          960: {
            slidesPerView: 2,
            spaceBetween: 35,
          }
        }
      });
      mySwipers[i].on('init', function () {
        let cnt = mySwipers[i].slides.length;
        $(`[data-tab="${tab}"] [data-swiper-num="cur"]`).text('01');
        $(`[data-tab="${tab}"] [data-swiper-num="all"]`).text(cnt < 10 ? ('0' + cnt) : cnt);
      });
      mySwipers[i].on('slideChange', function () {
        let cnt = mySwipers[i].realIndex + 1;
        $(`[data-tab="${tab}"] [data-swiper-num="cur"]`).text(cnt < 10 ? ('0' + cnt) : cnt);
      });
      mySwipers[i].init();
    });
  }
};

const mouseMove = () => {
  $(document).on('mousemove', '.clients-section', (e) => {
    const xPos = e.clientX;
    const yPos = e.clientY;
    const posX = (xPos - $(window).width()/2) * 0.05;
    const posY = (yPos - $(window).height()/2) * 0.05;
    if($('.clients-section__map-bg').length) {
      TweenLite.to($('.clients-section__map-bg')[0], 0, {
        x: `${posX}px`,
        y: `${posY}px`,
        ease: Power1.easeOut,
      });
    }
    if($('.clients-section__map-lines').length) {
      TweenLite.to($('.clients-section__map-lines')[0], 0, {
        x: `${posX / 2}px`,
        y: `${posY / 2}px`,
        ease: Power1.easeOut,
      });
    }
  });
  $(document).on('mousemove', '.about-section', (e) => {
    const xPos = e.clientX;
    const yPos = e.clientY;
    const posX = -(xPos - $(window).width()/2) / 50;
    const posY = (yPos - $(window).height()/2) / 24;
    if($('.about-section__bg .figure').length) {
      TweenLite.to($('.about-section__bg .figure')[0], 0, {
        x: `${posX}px`,
        y: `${posY}px`,
        ease: Power1.easeOut,
      });
    }
  });
  $(document).on('mousemove', '.get-in-touch-section', (e) => {
    const xPos = e.clientX;
    const yPos = e.clientY;
    const posX = -(xPos - $(window).width()/2) / 64;
    const posY = -(yPos - $(window).height()/2) / 50;
    if($('.get-in-touch-section__bg .figure').length) {
      TweenLite.to($('.get-in-touch-section__bg .figure')[0], 0, {
        x: `${posX}px`,
        y: `${posY}px`,
        ease: Power1.easeOut,
      });
    }
  });
  $(document).on('mousemove', (e) => {
    const xPos = e.clientX;
    const yPos = e.clientY;
    const posX = (xPos - $(window).width()/2) * 0.05;
    const posY = (yPos - $(window).height()/2) * 0.05;
    if($('.home-section__bg').length) {
      TweenLite.to($('.home-section__bg')[0], 0, {
        x: `${-posX / 3}px`,
        // y: `${-posY / 3}px`,
        ease: Power1.easeOut,
      });
    }
    if($('.home-section__bg .figure').length) {
      TweenLite.to($('.home-section__bg .figure')[0], 0, {
        x: `${-posX / 4.5}px`,
        // y: `${-posY / 3}px`,
        ease: Power1.easeOut,
      });
    }
  })
};

const titles = () => {
  $('[data-animate-title] > *,[data-animate-link] > *,[data-animate-menu]').each((i, el) => {
    const $el = $(el);
    const _html = $el.html();
    let words = _html.split(' ');
    let _newHtml = '';
    words.map(word => {
      _newHtml += '<span class="w">';
      if (word.indexOf('&nbsp;')) {
        word = word.replace('&nbsp;', ' ');
      }
      if (word === "&amp;" || word === '&') {
        _newHtml += `<span class="l">${word}</span>`;
      } else {
        word.split('').map(letter => {
          if (letter === ' ') {
            _newHtml += '<span class="l">&nbsp;</span>';
          } else {
            _newHtml += `<span class="l">${letter}</span>`;
          }
        });
      }
      _newHtml += '</span> ';
    });
    $el.html(_newHtml);
  });

  $(document).on('mouseenter', '[data-animate-menu]', function () {
    const $link = $(this);
    $link.find('span.l').each((i, el) => {
      TweenLite.to(el, .12, {
        color: '#FE5539',
        delay: (.03 * i)
      });
      TweenLite.to(el, .12, {
        color: '#FFFFFF',
        delay: (.03 * i) + .24
      });
    });
  });

  $(document).on('mouseenter', '[data-animate-title]', function () {
    const $link = $(this);
    $link.find('b span.l').each((i, el) => {
      TweenLite.to(el, .07, {
        transform: `scale(1.18) translate3d(8px, 0, 0)`,
        color: '#FE5539',
        delay: (.03 * i)
      });
      TweenLite.to(el, .24, {
        transform: 'scale(1) translate3d(0, 0, 0)',
        color: '#FFFFFF',
        delay: (.03 * i) + .08
      });
    });
  });

  $(document).on('mouseenter', '[data-animate-link]', function () {
    const $link = $(this);
    $link.find('b span.l').each((i, el) => {
      const del = Math.round((i + 2) * 1.2) + 15;
      TweenLite.to(el, .1, {
        transform: `scale(1) translate3d(${del}px, 0, 0)`,
        color: '#FE5539',
        delay: (.045 * i)
      });
      TweenLite.to(el, .3, {
        transform: 'scale(1) translate3d(0, 0, 0)',
        color: '#1B1E23',
        delay: (.045 * i) + .12
      });
    });
  });
};

const form = () => {
  $(document).on('input, blur', 'form[data-form="ajax-send"] input, form[data-form="ajax-send"] select', function (e) {
    const $inp = $(this);
    if ($inp.val() === '' || $inp.val() === '0') {
      $inp.removeClass('not-empty');
    } else {
      $inp.addClass('not-empty');
      $inp.closest('.inp-wrap').removeClass('error');
    }
    return false;
  });
  $(document).on('change', 'form[data-form="ajax-send"] [name="school"]', function (e) {
    const val = parseInt($(this).val() || 0);
    const $form = $(this).closest('form');
    if (val > 0) {
      $form.find(`[data-branch-id]`).hide();
      $form.find(`[data-branch-id="${val}"]`).show();
      $form.find(`[data-branch-id="${val}"] select`).focus();
    }
  });
  $(document).on('keyup', 'form[data-form="ajax-send"] [name="phone"]', function (e) {
    const $inp = $(this);
    let val = $inp.val();
    let numbs = val.match(/\d/g) || '';
    if (numbs) {
      numbs = numbs.join('');
    }
    numbs = '+' + numbs;
    if (val !== numbs) {
      $inp.val(numbs);
    }
  });
  $(document).on('submit', 'form[data-form="ajax-send"]', function (e) {
    e.preventDefault();
    const $form = $(this);
    const $inpName = $form.find('[name="name"]');
    const $inpPhone = $form.find('[name="phone"]');
    const $inpSchool = $form.find('[name="school"]');
    const $submit = $form.find('[type="submit"]');
    const $info = $form.find('.info-status');
    let $inpGroup = undefined;
    let br_id = 0;
    let gr_id = 0;
    let err = false;

    if ($inpSchool.val() === '' || $inpSchool.val() === '0') {
      $inpSchool.closest('.inp-wrap').addClass('error');
      $form.find('.inp-wrap[data-branch-id]').hide();
      $inpSchool.focus();
      err = true;
    } else {
      br_id = parseInt($inpSchool.val() || 0);
      $inpGroup = $form.find(`[data-branch-id="${br_id}"] [name="group"]`);
      if ($inpGroup.val() === '' || $inpGroup.val() === '0') {
        $inpGroup.closest('.inp-wrap').addClass('error');
        $inpGroup.focus();
        err = true;
      }
    }
    if(!$inpGroup) {
      err = true;
    } else {
      gr_id = parseInt($inpGroup.val() || 0);
    }
    if ($inpPhone.val() === '' || $inpPhone.val() === '+' || $inpPhone.val().length < 11) {
      $inpPhone.closest('.inp-wrap').addClass('error');
      $inpPhone.focus();
      err = true;
    }
    if ($inpName.val() === '' || $inpName.val().length < 2) {
      $inpName.closest('.inp-wrap').addClass('error');
      $inpName.focus();
      err = true;
    }
    if (!err) {
      $submit.attr('disabled', 'disabled');
      const to = $form.attr('action') || '/application/service';
      $.post(to, {
          _token: $form.find('[name="_token"]').val() || '',
          name: $inpName.val(),
          phone: $inpPhone.val(),
          branch_id: br_id,
          group: gr_id,
        })
        .done((res) => {
          $info.addClass('success');
          setTimeout(() => {
            $form.find('input,textarea').removeClass('not-empty').val('');
            $form.find('.inp-wrap').removeClass('error');
            $info.removeClass('failure success');
            setTimeout(() => {
              const $cls = $form.parent().find('.panda_mdl__close');
              if ($cls.length) {
                $cls.click();
              }
            }, 800);
          }, 3500);
        })
        .fail(() => {
          $info.addClass('failure');
          setTimeout(() => {
            $info.removeClass('failure success');
          }, 3500);
        })
        .always(() => {
          $submit.removeAttr('disabled');
        })
    }

    return false;
  });
};
