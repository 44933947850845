'use strict';

import {addCustomEventListener} from '../helpers';

export const projectScrollTo = (() => {
  const scrollToBlock = (name) => {
    const block = document.querySelector(`[data-scroll-section="${name}"]`);
    if(!block) return;

    window.scrollBy({
      top: block.getBoundingClientRect().top - (document.querySelector(`header`).offsetHeight || 0),
      left: 0,
      behavior: 'smooth'
    });
  };

  const eventListeners = () => {
    addCustomEventListener('[data-scroll-to]', 'click', function (e) {
      scrollToBlock(e.target.dataset['scrollTo'] || null);
    });
  };

  const init = () => {
    if (!document.querySelectorAll(`[data-scroll-to]`).length) return false;

    eventListeners();
  };

  return {
    init
  }
})();


