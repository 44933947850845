'use strict';

import {addCustomEventListener, addClass, removeClass} from '../helpers';

export const tabs = (() => {
  const _tabs = document.querySelectorAll('.tabs_block');

  const tabsInit = () => {
    _tabs.forEach((el, i) => {
      const $tabsBlock = $(el);
      $tabsBlock.find('.tabs_block__head__btn:first').addClass('active');
      $tabsBlock.find('.tabs_block__tab_one:first').addClass('active');

      $tabsBlock.on('click', '.tabs_block__head__btn', function () {
        const tab = parseInt($(this).data('tab'));

        $tabsBlock.find(`.tabs_block__head__btn`).removeClass('active');
        $tabsBlock.find(`.tabs_block__tab_one`).removeClass('active');
        $tabsBlock.find(`.tabs_block__head__btn[data-tab="${tab}"]`).addClass('active');
        $tabsBlock.find(`.tabs_block__tab_one[data-tab="${tab}"]`).addClass('active');
      });
    });
  };

  const init = () => {
    if (!_tabs.length) return false;

    tabsInit();
  };

  return {
    init
  }
})();


