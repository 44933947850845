'use strict';

export const hasClass = (element, selector) => {
  return (" " + element.className + " ").replace(/[\n\t]/g, " ").indexOf(" " + selector + " ") >= 0;
};

export const addClass = (_el, value) => {
  if(!_el) return;
  const classes = value.split(" ");
  const elements = (_el.length) ? _el : [_el];

  elements.forEach(element => {
    if (typeof element.classList === "undefined") return;
    classes.forEach(one => {
      if (one !== "" && !hasClass(element, one)) {
        element.classList.add(one);
      }
    });
  });
};

export const removeClass = (_el, value) => {
  if(!_el) return;
  const classes = value.split(" ");
  const elements = (_el.length) ? _el : [_el];

  elements.forEach(element => {
    if (typeof element.classList === "undefined") return;
    classes.forEach(one => {
      if (one !== "" && hasClass(element, one)) {
        element.classList.remove(one);
      }
    });
  });
};

export const addCustomEventListener = function (selector, event, handler) {
  //since the root element is set to be body for our current dealings
  document.querySelector('body').addEventListener(event, function (evt) {
      let targetElement = evt.target;
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          handler(evt);
          return;
        }
        targetElement = targetElement.parentElement;
      }
    },
    true
  );
};

export const fadeIn = function ( elem, ms ) {
  if( ! elem ) return;

  elem.style.opacity = 0;
  elem.style.filter = "alpha(opacity=0)";
  elem.style.display = "inline-block";
  elem.style.visibility = "visible";

  if( ms ) {
    let opacity = 0;
    const timer = setInterval( function() {
      opacity += 50 / ms;
      if( opacity >= 1 ) {
        clearInterval(timer);
        opacity = 1;
      }
      elem.style.opacity = opacity;
      elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
    }, 50 );
  } else {
    elem.style.opacity = 1;
    elem.style.filter = "alpha(opacity=1)";
  }
};

export const setTransformStyle = function ( _el, transform ) {
  if (_el) {
    _el.style["transform"] = transform;
    _el.style["webkitTransform"] = transform;
    _el.style["mozTransform"] = transform;
    _el.style["msTransform"] = transform;
  }
};

export const fadeOut = function ( elem, ms ) {
  if( ! elem ) return;

  if( ms ) {
    let opacity = 1;
    const timer = setInterval( function() {
      opacity -= 50 / ms;
      if( opacity <= 0 ) {
        clearInterval(timer);
        opacity = 0;
        elem.style.display = "none";
        elem.style.visibility = "hidden";
      }
      elem.style.opacity = opacity;
      elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
    }, 50 );
  } else {
    elem.style.opacity = 0;
    elem.style.filter = "alpha(opacity=0)";
    elem.style.display = "none";
    elem.style.visibility = "hidden";
  }
};
