'use strict';

import {addClass} from '../helpers';
import {mapTypes, mapStyles, setMarkerInfoWindow} from './const-for-maps';

export const projectsMap = (() => {
  const mapData = mapTypes[0];
  const projects = [];
  const markers = [];
  const infoWindows = [];
  let _mapBlock;
  let map;

  const initMap = () => {
    const center = {
      lat: 0,
      lng: 0,
    };
    projects.forEach(one => {
      center.lat += one.lat;
      center.lng += one.lng;
    });
    center.lat = center.lat / projects.length;
    center.lng = center.lng / projects.length;
    map = new google.maps.Map(_mapBlock, {
      center,
      zoom: 12,
      styles: mapStyles,
    });

    projects.forEach(one => {
      const marker = new google.maps.Marker({
        position: {
          lat: one.lat,
          lng: one.lng,
        },
        icon: mapTypes[one.type].icon,
        map
      });
      markers.push(marker);
      setMarkerInfoWindow(map, marker, one);
    });
  };

  const init = () => {
    _mapBlock = document.querySelector(`#projectsMap`);
    if (!_mapBlock) return false;
    document.querySelectorAll('[data-project="on-map"]').forEach(_item => {
      projects.push({
        lat: parseFloat(_item.dataset['lat']),
        lng: parseFloat(_item.dataset['lng']),
        type: parseInt(_item.dataset['type']),
        thumb: _item.dataset['thumb'],
        name: _item.querySelector('.project-item__addr').innerHTML,
        addr: _item.querySelector('.project-item__title').innerHTML,
      });
    });

    initMap();
  };

  return {
    init
  }
})();


