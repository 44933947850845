'use strict';

import {addCustomEventListener, setTransformStyle, addClass, removeClass} from '../helpers';
import Tablesort from '../../vendor/tablesort.min';

export const house = (() => {
  const _house = document.querySelector('[data-house="parent"]');
  const _filters = document.querySelector('[data-house="filters"]');
  const _table = document.querySelector('[data-house="table"]');
  const _popup = document.querySelector('[data-house="popup"]');
  const _picBlock = document.querySelector('[data-house="picture"]');
  let displayStatus = 'list';
  const apartment = {
    id: null,
    name: null,
    location: null,
    direction: null,
    floor_plan: null,
    block: null,
    floor: null,
    rooms: null,
    image: null,
    space_living: null,
    space_balcony: null,
    space_terrace: null,
    space_garden: null,
    price: null,
    price_owner: null,
  };
  const floorsData = [];

  const apartmentToView = () => {
    Object.entries(apartment).forEach(([key, value]) => {
      switch (key) {
        case 'location':
          const degs = parseInt(value || 0);
          setTransformStyle(_house.querySelector(`[data-house-popup="${key}"]`), `rotate(${(degs > 180 ? 0 : -90)}deg)`);
          setTimeout(() => {
            setTransformStyle(_house.querySelector(`[data-house-popup="${key}"]`), `rotate(${degs}deg)`);
          }, 50);
          break;
        case 'floor_plan':
        case 'image':
          _house.querySelector(`[data-house-popup="${key}"]`).innerHTML = value ? `<img src="${value}" alt=""/>` : '';
          break;
        case 'id':
          console.log('set id in popup-input', value || "");
          document.querySelector(`#popup_apartment_id`).value = value || "";
          break;
        default:
          _house.querySelector(`[data-house-popup="${key}"]`).innerHTML = value;
      }
    });
  };

  const setApartment = (params) => {
    Object.entries(params).forEach(([key, value]) => {
      if (key in apartment) {
        apartment[key] = value;
      }
    });
    apartmentToView();
  };

  const clearApartment = () => {
    Object.entries(apartment).forEach(([key, value]) => {
      apartment[key] = null;
    });
    apartmentToView();
  };

  const setDisplay = (status = 'list') => {
    document.querySelector('[name="display_apart[]"]').checked = false;
    if (status === 'single') {
      document.querySelector('#display_apart_single').checked = true;

    } else if (status === 'picture') {
      document.querySelector('#display_apart_picture').checked = true;

    } else {
      document.querySelector('#display_apart_list').checked = true;
    }
  };

  const tableSortExtendNumber = () => {
    const a=function(a){
      return a.replace(/[^\-?0-9.]/g,"")
    };
    const b=function(a,b){
      return a=parseFloat(a),b=parseFloat(b),a=isNaN(a)?0:a,b=isNaN(b)?0:b,a-b;
    };
    Tablesort.extend("number",function(a){
      return a.match(/^[-+]?[£\x24Û¢´€]?\d+\s*([,\.]\d{0,2})/)||a.match(/^[-+]?\d+\s*([,\.]\d{0,2})?[£\x24Û¢´€]/)||a.match(/^[-+]?(\d)*-?([,\.]){0,1}-?(\d)+([E,e][\-+][\d]+)?%?$/)
    },function(c,d){
      return c=a(c),d=a(d),b(d,c)
    });
  };
  const tableInit = () => {
    if (!_table) return false;

    tableSortExtendNumber();

    new Tablesort(_table);

    addCustomEventListener('.house__top__label', 'click', function (e) {
      clearApartment();
    });
    addCustomEventListener('[data-apartment_id]', 'click', function (e) {
      const _el = e.target.closest('tr') || e.target;
      const apart_id = parseInt(_el.getAttribute('data-apartment_id'));
      const params = JSON.parse(_el.getAttribute('data-params'));

      setApartment(params);
      setTimeout(() => {
        setDisplay('single');
      });
    });

  };

  const opacities = {
    default: 0,
    active: 0.5,
    hover: 0.8,
    step: {
      to: 0.04,
      from: 0.025,
    },
  };
  let activeFloor = null;
  const floorsInit = () => {
    _house.querySelectorAll('[data-house-floor]').forEach((_floor, i) => {
      const isActive = (i === 0);
      const _id = parseInt(_floor.getAttribute('data-house-floor'));
      const _cnt = parseInt(_floor.getAttribute('data-house-aparts_cnt'));
      const floorKoors = JSON.parse(_floor.getAttribute('data-house-floor_koors'));
      const apartsKoors = JSON.parse(_floor.getAttribute('data-house-aparts_koors'));
      if (isActive) {
        addClass(_floor, 'active');
        activeFloor = _id;
      }

      const aparts = [];
      let apartActive = true;
      Object.entries(apartsKoors).forEach(([apId, apart]) => {
        const fillStatus = apart.status == 2 ? '#9b9f99' : (apart.status == 1 ? '#9cc5d9' : '#8db889');
        aparts.push({
          isActive: apartActive,
          _id: parseInt(apId),
          koors: apart.koors,
          status: apart.status,
          fill: fillStatus,
          stroke: '#619d63',
          opacity: apartActive ? opacities.active : opacities.default,
          hovered: false,
        });
      });
      apartActive = false;
      floorsData.push({
        isActive,
        _id,
        _cnt,
        koors: floorKoors,
        aparts,
        fill: _cnt < 5 ? '#8db889' : '#9b9f99',
        stroke: '#619d63',
        opacity: isActive ? opacities.active : opacities.default,
        hovered: false,
      });
    });
    pictureInit();
  };

  const pictureInit = () => {
    if (!_picBlock) return false;

    const _picCanvas = _picBlock.querySelector('[data-house-picture="canvas"]');
    if (!_picCanvas) return false;

    const ctx = _picCanvas.getContext("2d");
    const cW = _picCanvas.offsetWidth;
    const cH = _picCanvas.offsetHeight;
    let mouse = {x: -1, y: -1};
    let flrMouse = {x: -1, y: -1};
    let hoveredFloor = null;
    let hoveredApart = null;
    const drawPicture = () => {
      ctx.clearRect(0, 0, _picCanvas.width, _picCanvas.height);
      let isHovered = false;
      floorsData.forEach((floor, fK) => {
        ctx.beginPath();
        ctx.fillStyle = floor.fill;
        ctx.strokeStyle = floor.stroke;
        ctx.globalAlpha = floor.opacity;
        drawItemKoors(ctx, floor.koors, cW, cH);
        ctx.closePath();
        const isPointInPath = ctx.isPointInPath(mouse.x, mouse.y);
        if (isPointInPath) {
          isHovered = true;
          hoveredFloor = floor;
        }
        floorsData[fK].hovered = isPointInPath;
        ctx.fill();
        ctx.stroke();

        if (floor.isActive) {
          const _floorCanvas = _picBlock.querySelector(`#floor_${floor._id}_aparts`);
          if (_floorCanvas) {
            drawApartsOnFloor(_floorCanvas, floor.aparts);
          }
        }
      });
      if (!isHovered) {
        hoveredFloor = null;
      }
      _picCanvas.style['cursor'] = isHovered ? 'pointer' : 'default';
    };
    const drawApartsOnFloor = (canvas, aparts) => {
      const flrCtx = canvas.getContext("2d");
      const flrW = canvas.offsetWidth;
      const flrH = canvas.offsetHeight;
      const drawAparts = () => {
        flrCtx.clearRect(0, 0, canvas.width, canvas.height);
        let isHovered = false;
        aparts.forEach((item, aK) => {
          flrCtx.beginPath();
          flrCtx.fillStyle = item.fill;
          flrCtx.strokeStyle = item.stroke;
          flrCtx.globalAlpha = item.opacity;
          drawItemKoors(flrCtx, item.koors, flrW, flrH);
          flrCtx.closePath();
          const isPointInPath = flrCtx.isPointInPath(flrMouse.x, flrMouse.y);
          if (isPointInPath) {
            isHovered = true;
            hoveredApart = item;
          }
          aparts[aK].hovered = isPointInPath;
          flrCtx.fill();
          flrCtx.stroke();
        });
        if (!isHovered) {
          hoveredApart = null;
        }
        canvas.style['cursor'] = isHovered ? 'pointer' : 'default';
      };
      flrCtx.canvas.width = flrW;
      flrCtx.canvas.height = flrH;
      flrCtx.lineWidth = 1;

      drawAparts();
    };
    const drawItemKoors = (ctx, koors, width, height) => {
      koors.forEach((koor, i) => {
        const x = width * (koor.x / 100);
        const y = height * (koor.y / 100);
        if (!i) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });
    };
    const run = function () {
      requestAnimationFrame(run);
      let needRedraw = false;
      const hovFunc = (item) => {
        let opacity = item.opacity;
        if (item.hovered) {
          if (opacity < opacities.hover) {
            opacity += opacities.step.to;
          } else {
            opacity = opacities.hover;
          }
        } else {
          if (item.isActive) {
            if (opacity > opacities.active) {
              opacity -= opacities.step.from;
            } else {
              opacity = opacities.active;
            }
          } else {
            if (opacity > 0) {
              opacity -= opacities.step.from;
            } else {
              opacity = opacities.default;
            }
          }
        }
        if (opacity !== item.opacity) {
          opacity = Math.round(opacity * 1000) / 1000;
          opacity = Math.min(opacity, 1);
          opacity = Math.max(opacity, 0);
          item.opacity = opacity;
          needRedraw = true;
        }
      };
      floorsData.forEach(floor => {
        hovFunc(floor);
        floor.aparts.forEach(apart => {
          hovFunc(apart);
        });
      });
      if (needRedraw) {
        drawPicture();
      }
    };
    ctx.canvas.width = cW;
    ctx.canvas.height = cH;
    ctx.lineWidth = 1;

    _picCanvas.onmouseleave = function () {
      mouse.x = -1;
      mouse.y = -1;
      drawPicture();
    };
    _picCanvas.onmousemove = function (e) {
      mouse.x = e.clientX - this.getBoundingClientRect().left;
      mouse.y = e.clientY - this.getBoundingClientRect().top;
      drawPicture();
    };
    _picCanvas.onmousedown = function (e) {
      if (hoveredFloor) {
        _house.querySelectorAll('[data-house-floor]').forEach((_floor, i) => {
          const _id = parseInt(_floor.getAttribute('data-house-floor'));
          const isActive = (_id === hoveredFloor._id);
          if (isActive) {
            addClass(_floor, 'active');
          } else {
            removeClass(_floor, 'active');
          }
          floorsData.forEach(floor => {
            if (floor._id === _id) {
              floor.isActive = isActive;
              floor.opacity = isActive ? opacities.active : opacities.default;
            }
          });
        });
      }
    };
    const canvasAparts = _picBlock.querySelectorAll('[data-house-floor] canvas') || [];
    canvasAparts.forEach(canvas => {
      canvas.onmouseleave = function () {
        flrMouse.x = -1;
        flrMouse.y = -1;
        drawPicture();
      };
      canvas.onmousemove = function (e) {
        flrMouse.x = e.clientX - this.getBoundingClientRect().left;
        flrMouse.y = e.clientY - this.getBoundingClientRect().top;
        drawPicture();
      };
      canvas.onmousedown = function (e) {
        if (hoveredApart) {
          const needApart = _house.querySelector(`[data-apartment_id="${hoveredApart._id}"]`);
          if(needApart) {
            needApart.click();
          }
          console.log({needApart});
        }
      }
    });
    drawPicture();
    run();

  };


  const init = () => {
    if (!_house) return false;

    tableInit();
    floorsInit();
  };

  return {
    init
  }
})();
