import {globalFunc} from './modules/global';
import {mapFunc} from './modules/map';
import {forms} from './modules/forms';
import {allBlocksInit} from './modules/blocks';

window.axios = require('./modules/axios.min');
window.axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

window.onLazyImageLoad = (_el) => {
  const _lazy = _el.closest(".sh-lazy");
  if (_lazy) {
    _lazy.querySelector('.sh-lazy__img').style['opacity'] = 0;
    setTimeout(() => {
      _lazy.outerHTML = _el.closest("picture").outerHTML;
    }, 300);
  }
};

const app = () => {
  // globalFunc();
  mapFunc();
  allBlocksInit();
  forms.init();

  $(document).on('click', '[data-open-popup]', function () {
    const type = $(this).attr('data-open-popup');
    const $modal = $(`[data-popup="${type}"]`);
    if ($modal.length) {
      $modal.addClass('opened');
    }
  });
  $('.popup-form').on('click', '.popup-form__bg, .popup-form__close', function () {
    $(this).closest('.popup-form').removeClass('opened');
  });

  lazyImage();
  $(window).on('scroll', function () {
    const topBlock = document.querySelector('.home_slider');
    lazyImage();
    if (topBlock) {
      const scrTop = $(window).scrollTop();
      const headerH = document.querySelector('header').offsetHeight;
      const mainBlock = document.querySelector('main');

      if (topBlock.offsetHeight - headerH < scrTop) {
        if (mainBlock.getAttribute('data-header') !== 'dark') {
          mainBlock.setAttribute('data-header', 'dark');
        }
      } else {
        if (mainBlock.getAttribute('data-header') !== 'light') {
          mainBlock.setAttribute('data-header', 'light');
        }
      }
    }
  })
};


const lazyImage = () => {
  const _images = document.querySelectorAll('[data-lazy-picture]') || [];
  _images.forEach(_block => {
    const _el = _block.closest('[data-lazy-parent]') || _block;
    const top = _el.getBoundingClientRect().top || 0;
    if (top < window.innerHeight * 2) {
      _block.outerHTML = `<div class="sh-lazy">` +
        `${_block.innerHTML}` +
        `<div class="sh-lazy__load">${_block.getAttribute('data-lazy-picture')}</div>` +
        `</div>`;
    }
  });
};

document.addEventListener('DOMContentLoaded', app);
