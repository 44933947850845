'use strict';

import {addClass} from '../helpers';
import {mapStyles, mapTypes, setMarkerInfoWindow} from './const-for-maps';

export const projectSingleMap = (() => {
  const attrs = {
    lat: null,
    lng: null,
    type: 0,
    thumb: null,
    addr: null,
    name: null,
  };
  let _mapBlock;
  let map;
  let marker;

  const initMap = () => {
    const mapData = mapTypes[attrs.type];
    map = new google.maps.Map(_mapBlock, {
      center: {
        lat: attrs.lat,
        lng: attrs.lng,
      },
      zoom: mapData.zoom,
      styles: mapStyles,
    });
    marker = new google.maps.Marker({
      position: {
        lat: attrs.lat,
        lng: attrs.lng,
      },
      icon: mapData.icon,
      map
    });
    setMarkerInfoWindow(map, marker, attrs);
  };

  const init = () => {
    _mapBlock = document.querySelector(`#singleMap`);
    if (!_mapBlock) return false;
    attrs.lat = parseFloat(_mapBlock.dataset['lat']);
    attrs.lng = parseFloat(_mapBlock.dataset['lng']);
    attrs.type = parseInt(_mapBlock.dataset['type']);
    attrs.thumb = _mapBlock.dataset['thumb'];
    attrs.addr = _mapBlock.dataset['addr'];
    attrs.name = _mapBlock.dataset['name'];

    initMap();
  };

  return {
    init
  }
})();


