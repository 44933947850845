'use strict';

import {addClass} from '../helpers';

export const projectMiniPhotos = (() => {
  let needCnt = 3;
  let _block = null;

  const showPhotos = () => {
    const _photos = document.querySelectorAll('.photos_block__item');
    let html = '';
    let i = 0;
    if (needCnt > _photos.length) {
      needCnt = _photos.length;
    }
    _photos.forEach(_item => {
      i++;
      if (i <= needCnt) {
        const moreText = (_photos.length - needCnt > 0) ? `<span>+${_photos.length - needCnt}</span>` : ``;
        const spanHtml = `<div class="project_single__mini__one">${_item.innerHTML}</div>`;
        const btnHtml = `<button type="button" data-scroll-to="photos" class="project_single__mini__one lnk">${_item.innerHTML}${moreText}</button>`;
        html += (i === needCnt && moreText !== '') ? btnHtml : spanHtml;
      }
    });
    _block.innerHTML = html;
    setTimeout(() => {
      addClass(_block, 'loaded');
    }, 250);
  };

  const init = () => {
    _block = document.querySelector(`[data-project="mini-photos"]`);
    if (!_block) return false;

    showPhotos();
  };

  return {
    init
  }
})();


