'use strict';

import Swiper from "swiper";
// import {addCustomEventListener, addClass, removeClass} from '../helpers';

export const swiperGalleries = (() => {
  const _galleries = document.querySelectorAll('[data-gallery="swiper"]');

  const swiperInit = () => {
    _galleries.forEach((_el) => {
      const _gallery = _el.querySelector('.swiper-container');
      new Swiper(_gallery, {
        speed: 400,
        spaceBetween: 20,
        slidesPerView: 4,
        on: {
          click: function (event) {
            const _el = event.target.closest('[data-image-src]');
            if(_el) {
              window.openPopupImage(_el.dataset['imageSrc']);
            }
          },
          tap: function (event) {
            const _el = event.target.closest('[data-image-src]');
            if(_el) {
              window.openPopupImage(_el.dataset['imageSrc']);
            }
          },
        },
        // navigation: {
        //   nextEl: _next,
        //   prevEl: _prev,
        // },
        // pagination: {
        //   el: _pag,
        //   clickable: true,
        // },
        breakpoints: {
          0: {
            slidesPerView: 1
          },
          641: {
            slidesPerView: 2
          },
          781: {
            slidesPerView: 3
          },
        }
      });
    });
  };

  const init = () => {
    if (!_galleries.length) return false;

    swiperInit();
  };

  return {
    init
  }
})();


