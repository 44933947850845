'use strict';

import {house} from './blocks/house';
import {popupImage} from './blocks/popup-image';
import {homeSlider} from './blocks/home-slider';
import {homeProjects} from './blocks/swiper-home-projects';
import {swiperGalleries} from './blocks/swiper-galleries';
import {projectScrollTo} from './blocks/project-scroll-to';
import {projectMiniPhotos} from './blocks/project-mini-photos';
import {tabs} from './blocks/tabs';

export const allBlocksInit = () => {
  homeSlider.init();
  homeProjects.init();
  popupImage.init();
  swiperGalleries.init();
  projectMiniPhotos.init();
  projectScrollTo.init();
  tabs.init();
  house.init();
};
