'use strict';
import {projectSingleMap} from './maps/project-single-map';
import {projectsMap} from './maps/projects-map';
import {footerMap} from './maps/footer-map';

const allMapsInit = () => {
  projectSingleMap.init();
  projectsMap.init();
  footerMap.init();
};

export const mapFunc = () => {
  const google = window.google || null;
  if (!google) {
    setTimeout(mapFunc, 200);
  } else {
    allMapsInit();
  }
};
