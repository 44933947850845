'use strict';

import {addCustomEventListener, addClass, removeClass} from './helpers';

export const forms = (() => {
  const checkEmail = (email) => {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(email);
  };

  const inputEnter = (e) => {
    const _inp = e.target;
    if (_inp.value === '' || _inp.value === '0') {
      removeClass(_inp, 'not-empty');
    } else {
      addClass(_inp, 'not-empty');
      removeClass(_inp.closest('.inp-wrap'), 'err');
      removeClass(_inp.closest('.check-wrap'), 'err');
    }
    return false;
  };

  const phoneKeyup = (e) => {
    const _inp = e.target;
    let numbs = _inp.value.match(/\d/g) || '';
    if (numbs) {
      numbs = numbs.join('');
    }
    numbs = '+' + numbs;
    if (_inp.value !== numbs) {
      _inp.value = numbs;
    }
  };

  const formSubmit = (captchaToken, e) => {
    e.preventDefault();
    const _form = e.target;

    const btnSubmit = _form.querySelector('[type="submit"]');
    const infoBlock = _form.querySelector('.info-status');
    let err = false;
    let formData = new FormData();

    /**
     * get val data from all inputs, selects & textareas in ajax-forms to formData
     */
    removeClass(_form.querySelectorAll('.inp-wrap,.check-wrap'), 'err');
    _form.querySelectorAll('input, textarea').forEach((_inp) => {
      const name = _inp.getAttribute('name');
      const type = _inp.getAttribute('type');
      const isRequired = _inp.hasAttribute('required') || false;

      if (name === 'email' && !checkEmail(_inp.value)) {
        addClass(_inp.closest('.inp-wrap'), 'err');
        err = true;
      }
      if (isRequired && _inp.value === '' && type !== 'checkbox') {
        err = true;
        addClass(_inp.closest('.inp-wrap'), 'err');
      }
      if (isRequired && type === 'checkbox' && _inp.checked === false) {
        err = true;
        addClass(_inp.closest('.check-wrap'), 'err');
      }

      const value = (type === 'checkbox') ? _inp.checked : _inp.value;
      formData.append(name, value);
    });

    if (captchaToken) {
      formData.append('g-recaptcha-response', captchaToken);
    }
    /**
     * ajax send formData in ajax-forms
     */
    if (!err) {
      btnSubmit.setAttribute('disabled', 'disabled');

      axios({
        method: _form.getAttribute('method'),
        url: _form.getAttribute('action'),
        data: formData
      })
        .then(function (response) {
          addClass(infoBlock, 'success');

          setTimeout(() => {
            _form.querySelectorAll('input,textarea').forEach((_inp) => {
              if (_inp.getAttribute('type') !== 'hidden') {
                removeClass(_inp, 'not-empty');
                _inp.value = '';
              }
            });
            removeClass(_form.querySelector('.inp-wrap'), 'error');
            removeClass(infoBlock, 'failure success');
          }, 3500);
        })
        .catch(function (error) {
          addClass(infoBlock, 'failure');
          setTimeout(() => {
            removeClass(infoBlock, 'failure success');
          }, 3500);
        })
        .then(function () {
          btnSubmit.removeAttribute('disabled');
        });

    }

    return false;
  };

  const formSubmitClick = (e) => {
    e.preventDefault();
    const grecaptcha = window.grecaptcha || null;
    if (!grecaptcha) {
      formSubmit(null, e);
      return;
    }
    grecaptcha.execute('6LdERsUZAAAAAIm4D5vmhQV_t50JfCFr2ysYKlpO', {action: 'submit'}).then(function (token) {
      formSubmit(token, e);
    });
  };

  const init = () => {
    // axios.get('/contacts');
    addCustomEventListener('form[data-form="ajax-send"] input, form[data-form="ajax-send"] textarea', 'input', inputEnter);
    addCustomEventListener('form[data-form="ajax-send"] input, form[data-form="ajax-send"] textarea', 'blur', inputEnter);
    addCustomEventListener('form[data-form="ajax-send"] [name="phone"]', 'keyup', phoneKeyup);
    addCustomEventListener('form[data-form="ajax-send"]', 'submit', formSubmitClick);
  };

  return {
    init
  }
})();
