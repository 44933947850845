'use strict';

import {addClass} from '../helpers';
import {mapTypes, mapStyles, setMarkerInfoWindow} from './const-for-maps';

export const footerMap = (() => {
  const mapData = mapTypes[0];
  const attrs = {
    lat: null,
    lng: null,
    addr: null,
    phone: null,
    email: null,
  };
  let _mapBlock;
  let map;
  let marker;

  const initMap = () => {
    map = new google.maps.Map(_mapBlock, {
      center: {
        lat: attrs.lat,
        lng: attrs.lng,
      },
      zoom: mapData.zoom,
      styles: mapStyles,
    });
    marker = new google.maps.Marker({
      position: {
        lat: attrs.lat,
        lng: attrs.lng,
      },
      icon: mapData.icon,
      map
    });
    setMarkerInfoWindow(map, marker, attrs);
  };

  const init = () => {
    _mapBlock = document.querySelector(`#footerMap`);
    if (!_mapBlock) return false;
    attrs.lat = parseFloat(_mapBlock.dataset['lat']);
    attrs.lng = parseFloat(_mapBlock.dataset['lng']);
    attrs.addr = _mapBlock.dataset['addr'];
    attrs.phone = _mapBlock.dataset['phone'];
    attrs.email = _mapBlock.dataset['email'];

    initMap();
  };

  return {
    init
  }
})();


